.select-element{
        border: none;
        background-color: transparent;
        margin-top: 6px;
        margin-bottom: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        max-height: 9vh;
        min-height: 9vh;
        min-width: 300px;
        max-width: 47vw !important;
        width: 47vw !important;
        height:9vh;
        border-radius: 10px;
        transition: background-color 0.4s ease-in-out;
        margin-left: 3vw;
}

.select-element-icon-box{
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 75px;
        min-height: 40px;
        height: 7.5vh;
        max-width: 75px;
        min-width: 40px;
        width: 3.8vw;
        border-radius: 10px;
        background-color: #fff;
        margin-right: 1.5vw;
}
.select-element-icon{
        max-height: 40px;
        height: 4.5vh;
        min-height: 20px;
        max-width: 40px;
        min-height: 20px;
}

.remove-button{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
}

.remove-button-text{
        font-family: "Poppins", sans-serif;
        font-size: 1rem;
        font-weight: 600;
        color: #000000;
        margin-top: 2.1vh;
}
.remove-button-icon{
        max-height: 25px;
        height: 2.5vh;
        min-height: 20px;
        max-width: 25px;
        min-height: 20px;
        margin-left: 5px;
}

.remove-button:hover{
        cursor: pointer;
}
.remove-button:hover .remove-button-text{
        font-weight: 700;
}

