.create-function-panel-background{
    background-color: #FFEFF3;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.create-function-panel-body{
    width: 50vw;
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.create-function-panel-title-row{
    width: 50vw;
    height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.create-function-spacer-150px-vertical{
    height: 150px;
}

.create-element-box-for-hover{
    width: max-content;
    height: max-content;
}

.create-function-panel-loading-box{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
}

.area-of-focus-instruction-text{
    font-family: 'Roboto', sans-serif;
    font-size: 1.05rem;
    font-weight: 500;
    color: #000000;
    width: 50vw;
}

.space-left-3vw{
    margin-left: 3vw;
}

.create-function-panel-bottom-buttons{
    width: 47vw;
    margin-left: 3vw;
    margin-top: 5vh;
    height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}