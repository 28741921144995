.question-sidebar-element{
    border: none;
    background-color: transparent;
    margin-top: 6px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    max-height: 300px;
    min-width: 28vw;
    max-width: 350px;
    border-radius: 15px;
    transition: background-color 0.25s ease-in-out;
    overflow: hidden;
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    align-items: center;
}

.question-sidebar-element:hover{
    background-color: #eaeaea;
}

.question-sidebar-element-icon-box{
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 75px;
        height: 7.5vh;
        min-height: 55px;
        max-width: 75px;
        min-width: 55px;
        border-radius: 10px;
        transition: background-color 0.25s ease-in-out;
}
.question-sidebar-element-icon{
        max-height: 40px;
        height: 4.5vh;
        min-height: 30px;
        max-width: 40px;
        min-height: 30px;
}

.question-sidebar-element-textbox{
        margin-left: 15px;
        color: #000;
        font-family: "Poppins" , sans-serif;
        flex-direction: column;
}

.question-sidebar-element-title{
    font-size: 18px;
    font-weight: bold;
}

.question-sidebar-element-text{
    font-size: 16px;
    font-weight: normal;
    text-align: start;
    display: flex;
    word-wrap: break-word;
    height: auto;
    align-items: flex-start;
}