
.funnel-button{
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
    padding: 1vw;
    width: fit-content;
}

.funnel-button:hover{
    background-color: #FFEFF3;
    cursor: pointer;
}

.funnel-button-column{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: max-content;
    height: max-content;
}

.funnel-button-title{
    font-size: 3.3rem;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: black;
}

.funnel-button-subtitle{
    font-size:calc(12px + 0.2vw);
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: black;
}

.funnel-button-icon{
    width: 3vw;
    margin-left: 3vw;
}