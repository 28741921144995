
.result-header-box {
    width: 96vw;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 15px;
}

.logo-black {
    max-Height: 35px;
}

.navbar-box{
    display: flex;
    justify-content: space-between;
}
.nav-button{
    border: none;
    transition: none;
    background-color: transparent;
}

.clip-button{
    border: none;
    transition: none;
    background-color: transparent;
}

