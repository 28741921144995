
.add-filter-add-window {
    width: 30vw;
    height: 45vh;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: #FFFFFF;
    z-index: 1000;
    position: absolute; /* Change position to absolute */
    top: 43%; /* Adjust the top position */
    left: 73%; /* Adjust the left position */
    transform: translate(-50%, -50%); /* Center the component */
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: black;
}

.add-filter-add-window-title {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 700;
    padding-top: 10px;
    padding-bottom: 5px;
    padding-left: 20px;
    position: sticky;
    top: -1px;
    background-color: #FFFFFF;
}

.space-vertical-10px {
    height: 10px;
}

.add-filter-add-window::-webkit-scrollbar {
    width: 6px;
    border-radius: 5px;
}

.add-filter-add-window::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 10px;
}

.add-filter-add-window::-webkit-scrollbar-track {
    background-color: transparent;
    padding: 10px 0px;
}

