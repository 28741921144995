.clipboard-text-block {
    padding: 10px;
    position: relative;
    cursor: pointer;
    width: 90%;
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.clipboard-title {
    width: 100%;
    border: none;
    border-bottom: 1px solid transparent; /* Initially transparent bottom border */
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bold;
    outline: none; /* Removes default focus outline */
    transition: border-bottom-color 0.3s ease; /* Transition for the bottom border color */
}

.clipboard-title:focus {
    border-bottom-color: #000; /* Bottom border color changes on focus */
}

.clipboard-text-body-text{
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    margin-bottom: 5px;
    text-align: start;
}

.clipboard-button {
    display: none;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.clipboard-text-block:hover .clipboard-button {
    display: block;
    position: relative;
    bottom: 0px;
    right: 0px;
    border: none;
    transition: none;
    background-color: transparent;
}

.clipboard-button-icon{
    max-height: 25px;
    height: 2.5vh;
    max-width: 25px;
    min-width: 20px;
}

.clipboard-button-text{
    margin-left: 6px;
    font-size: 16px;
    font-weight: normal;
    color: #8d8d8d;
    font-family: "Poppins" , sans-serif;
    text-align: start;
    transition: color 0.4s;
}

.clipboard-button-icon:hover + .clipboard-button-text,
.clipboard-button-text:hover {
    color:#000;
    font-weight: bold;
}