.create-page-background{
    background-color: transparent;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed;
    display: flex; 
    flex-direction: row;
}

.create-page-left-panel{
    width: 35vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.create-page-title{
    font-family: "Popins", sans-serif;
    font-size: 40px;
    font-weight: 600;
    color: #000;
    margin-left: 3vw;
}

