body {
  font-family: "Poppins", sans-serif !important;
}

.backGround{
  position: fixed;
  overflow: hidden;
}

.leftPanel{
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 5vh 2vw;
}

.functionPanel{
  margin-top: 5vh;
  margin-left: 2vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}

.hello {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 50px;
  margin-bottom: 5px;
}

.Subtitle{
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-size: 20px;
  color: #7D7D7D;
}

.text-input {
  color: #262626 !important;
  font-size: 25px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  background-color: transparent;
  border: none !important;
}

.text-input-hello {
  color: #262626 !important;
  font-size: 25px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  background-color: transparent;
  border: none !important;
  margin-left: 0px;
}


.text-input:focus {
  color:#262626;
  border: none !important;
  outline: none !important;
}

.text-input::placeholder {
  color: #7D7D7D !important;
  font-size: 25px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}

.text-input-hello:focus {
  color:#262626;
  border: none !important;
  outline: none !important;
}

.text-input-hello::placeholder {
  color: #7D7D7D !important;
  font-size: 25px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}

.full-height {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.full-width {
  width: 100vh;
}

.remove-right {
  margin-right: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
}

.welcomeBackground{
  width: calc(100% - 10px);
  height: calc(100% - 30px);
  margin: 30px 30px 30px 0px;
}