.create-function-panel-background{
    background-color: #FFEFF3;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.create-function-panel-body{
    width: 50vw;
    min-height: 75vh;
    display: flex;
    flex-direction: column;
}

.create-function-panel-title-row{
    width: 50vw;
    height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.create-function-panel-bottom-buttons{
    width: 47vw;
    margin-left: 3vw;
    margin-top: 5vh;
    height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.option-detail-options-box{
    width: 50.2vw;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: black;
}


.option-detail-options-box::-webkit-scrollbar {
    width: 6px;
    border-radius: 5px;
}

.option-detail-options-box::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 10px;
}

.option-detail-options-box::-webkit-scrollbar-track {
    background-color: transparent;
    padding: 10px 0px;
}