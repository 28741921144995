
.navigate-button{
    border: none;
    width: max-content;
    min-width: 190px;
    height: fit-content;
    padding: 1vh 2vw 1vh 1.8vw;
    font-family: "Poppins", sans-serif;
    font-size: 0.9rem;
    font-weight: 500;
    transition: all 0.05s ease-in-out;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.navigate-button:hover{
    font-weight: 600;
}

.navigate-button-icon{
    margin-left: 10px;
    margin-right: 10px;
    height: 12px;
}

.navigate-button-text{
    white-space: nowrap;
    margin-bottom: 0px;
    margin-top: 0px;
}