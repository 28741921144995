.prompt-element{
        border: none;
        background-color: transparent;
        margin-top: 6px;
        margin-bottom: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        max-height: 75px;
        min-width: 32vw;
        height:9vh;
        border-radius: 15px;
        transition: background-color 0.25s ease-in-out;
}

.prompt-element:hover{
        background-color: rgba(255, 0, 0, 0.2);
}

.prompt-element-icon-box{
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 75px;
        height: 7.5vh;
        min-height: 55px;
        max-width: 75px;
        min-width: 55px;
        border-radius: 15px;
        background-color: #fff;
}
.prompt-element-icon{
        max-height: 40px;
        height: 4.5vh;
        min-height: 30px;
        max-width: 40px;
        min-height: 30px;
}

.prompt-element-text{
        margin-left: 15px;
        font-size: 17px;
        font-weight: 500;
        color: #000;
        font-family: "Poppins" , sans-serif;
        text-align: start;
}

@media only screen and (max-width: 768px) {
    .prompt-element {
        min-width: 32vw;
        height: 12vh;
        min-height: 35px;
    }
    
    .prompt-element-icon-box {
        max-height: 55px;
        height: 6.5vh;
        min-height: 30px;
        max-width: 55px;
        min-width: 30px;
    }
    .prompt-element-icon{
        max-height: 40px;
        height: 4.5vh;
        min-height: 20px;
        max-width: 40px;
        min-height: 20px;
    }
    
    .prompt-element-text {
        font-size: 14px;
    }
}

