.back-button-body{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-top: 4vh;
    margin-left: 3vw;
}

.back-button-icon{
    width: 25px;
    height: 25px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.back-button-body-text{
    font-family: "Popins", sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    margin-left: 10px;
}