.create-function-panel-background{
    background-color: #FFEFF3;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.create-function-panel-body{
    width: 50vw;
    height: 60vh;
    display: flex;
    flex-direction: column;
}

.create-function-spacer-50px-vertical{
    height: 50px;
}

.area-of-focus-instruction-text{
    font-family: 'Roboto', sans-serif;
    font-size: 1.05rem;
    font-weight: 500;
    color: #000000;
    width: 50vw;
}

.space-left-3vw{
    margin-left: 3vw;
}

.space-right-20px{
    margin-right: 20px;
}

.area-of-focus-textarea{
    width: 50vw;
    height: 30vh !important;
    border-radius: 10px;
    border: none;
    padding: 25px;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 600;
    color: #000000;
    margin-left: 3vw;
    resize: none;
    overflow: hidden;
}

.area-of-focus-submit-row{
    margin-top: 3vh;
    margin-left: 3vw;
    width: 50vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}