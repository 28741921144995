.prompt-box{
    display:flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    background-color: #FFEFF3;
    border-radius: 20px;
    padding: 30px 40px 30px 30px;
    max-width: 37vw;
    min-height: 80vh;
    max-height: 83vh;
}

.prompt-list-box{
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.prompt-list-box::-webkit-scrollbar {
    display: none;
}


.prompt-box-title{
    font-size: 20px;
    font-weight: 600;
    color: #000;
    font-family: "Poppins" , sans-serif;
    margin-bottom: 15px;
    margin-top: 5px;
}
