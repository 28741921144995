/* .site-navigation,
    .trans-navigation {
        padding: 1.25rem 0px;
       }

    .site-navigation .navbar-nav a.active,
    .trans-navigation .navbar-nav a.active {
        color: #0A1045;
    }

    .navbar-expand-lg.trans-navigation .navbar-nav .nav-link,
    .navbar-expand-lg.site-navigation .navbar-nav .nav-link {
        padding: 0.75rem 1.5rem;

    }

    .navbar-expand-lg.trans-navigation .navbar-nav .nav-link:last-child,
    .navbar-expand-lg.site-navigation .navbar-nav .nav-link:last-child {
        padding-right: 0px;
    }

    .site-navigation,
    .trans-navigation {
        transition: all 0.5s ease 0s;
    }

    .navbar-brand {
        padding-top: 0px;
        margin-right: 3.75rem;
        font-weight: 700;
        color: #fff;
    }

    .navbar-brand span {
        font-weight: 400;
    }

    .navbar-expand-lg.trans-navigation .navbar-nav .nav-link {
        color: #9E9E9E;
    }

    .trans-navigation {
        background: transparent;
    }

    .trans-navigation.header-white .navbar-nav a.active {
        color: #635CDB;
    }

    .navbar-brand img {
        max-width: 100%;
    }

    .trans-navigation.header-white .btn-white {
        background: #635CDB;
        color: #fff;
    }


    @media (max-width: 61.99rem) {

        .nav-wrapper {
            width: 100%;
            padding: 1rem 0;
            display: flex;
            box-shadow: 0px 10px 50px #010d8233;
        }
        .navbar-collapse {
            text-align: center;
        }

        .navbar-brand {
            background: #fff;
            border-radius: 1rem;
            padding: .8rem;
            margin-left: .5rem;
            max-height:60px;
        }

        .navbar-toggler {
            background: #fff;
            border-radius: 1rem;
            padding: 1rem;
            margin-left: 1.5rem;
            max-height:60px;
        }

        .navbar-toggler-icon {
            height: auto;
            padding: 0.3125rem 0px; */
                /* 5px / 16px = 0.3125rem */
            /* width: 0.0625rem; */
                /* 1px / 16px = 0.0625rem */
        /* }

        .trans-navigation {
            padding: 1.25rem 0.9375rem;

        }

        .navbar-expand-lg.trans-navigation .navbar-nav .nav-link {
            margin-bottom: 0.625rem;

        }

        .trans-navigation {
            background: none;
        }
    }

    .navbar-collapse.show {
        overflow-y: auto;
        max-height: calc(100vh - 6.25rem);

    } */


.nav{
    border-radius: 15px 15px 15px 15px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(105, 116, 221, 0.40);
    height: 96px;
}

.nav-profile{
    color: #9E9E9E;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.225px;
    border-radius: 32px;
    border: 2px solid #000;
    width: 80%;
    max-width: 229px;
    height: 50px;
}

.nav-profile-img{
    margin-left: -0.3rem;
}

.modal-profile-title{
    color: rgba(0, 0, 0, 0.80);
    text-align: center;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 500;
    letter-spacing: -0.6px;
}

.modal-field{
    color: #090E41;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.27px;
}