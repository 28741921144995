body {
  font-family: "Poppins", sans-serif !important;
}

.backGround{
  position: fixed;
  overflow: hidden;
}

.leftPanel{
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 2vh 0.2vw;
  align-items: flex-start; /* added property */
}

.functionPanel{
  margin-top: 3vh;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
}

.ChatUIHeader{
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 5vh;
  margin-left: 40px;
}

.Subtitle{
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-size: 20px;
  color: #7D7D7D;
}

.text-input {
  color: #262626 !important;
  font-size: 25px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  background-color: transparent;
  border: none !important;
  margin-left: 40px;
}

.text-input:focus {
  color:#262626;
  border: none !important;
  outline: none !important;
}

.text-input::placeholder {
  color: #7D7D7D !important;
  font-size: 25px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}

.full-height {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.full-width {
  width: 100vh;
}

.remove-right {
  margin-right: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
}

.welcomeBackground{
  width: calc(100% - 10px);
  height: calc(100% - 30px);
  margin: 30px 30px 30px 0px;
}