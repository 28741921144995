
.popup-body-row-input-field{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 5vh;
    min-height: 30px;
    width: 25vw;
    border-radius: 20px;
}

.popup-body-input-field::placeholder{
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #000;
    opacity: 0.5;
}

.popup-body-input-field:focus{
    outline: none;
}

.popup-body-input-field {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #000;
    padding-left: 15px; 
    border: none;
    width: 20vw;
    border-radius: 10px;
    background-color: #FFEFF3;
}

.popup-body-input-button{
    border: none;
    border-radius: 10px;
    color: white;
    background-color: #FF003E;
}
