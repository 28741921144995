.sidebar-element{
    border: none;
    background-color: transparent;
    margin-top: 6px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-height: 75px;
    min-width: 28vw;
    height:9vh;
    border-radius: 15px;
    transition: background-color 0.25s ease-in-out;
}

.sidebar-element:hover{
    background-color: #eaeaea;
}
.sidebar-element-headerblock{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.sidebar-element-icon-box{
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 75px;
        height: 7.5vh;
        min-height: 55px;
        max-width: 75px;
        min-width: 55px;
        border-radius: 10px;
        transition: background-color 0.25s ease-in-out;
}
.sidebar-element-icon{
        max-height: 40px;
        height: 4.5vh;
        min-height: 30px;
        max-width: 40px;
        min-height: 30px;
}

.sidebar-element-text{
        margin-left: 15px;
        font-size: 20px;
        font-weight: normal;
        color: #000;
        font-family: "Poppins" , sans-serif;
        text-align: start;
        
}
.sidebar-element-options-list{
    margin-left: 40px;
    display: flex;
    flex-direction: row;
    max-height: 220px;
    height: 14vh;
    min-height: 100px;
    overflow-y: hidden;
}
.sidebar-element-options{
    margin-left: 10px;
    margin-top: 0px;
    max-height: 200px;
    overflow-y: scroll !important;
}

.sidebar-element-options::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-width: 768px) {
    .sidebar-element {
        min-width: 32vw;
        height: 12vh;
        min-height: 35px;
    }
    
    .sidebar-element-icon-box {
        max-height: 55px;
        height: 6.5vh;
        min-height: 30px;
        max-width: 55px;
        min-width: 30px;
    }
    .sidebar-element-icon{
        max-height: 40px;
        height: 4.5vh;
        min-height: 20px;
        max-width: 40px;
        min-height: 20px;
    }
    
    .sidebar-element-text {
        font-size: 14px;
    }
}

