.sidebar{
    border-radius: 24px;
    position: sticky !important;
    top: 0;
    background-color: #090E41;
    min-height: 80vh;
    font-family: Poppins;
}

.sidebar-title{
    color: #FFF;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: -0.45px;
    
}

.subteam-img{
    width: 20px;
}

.subteam-name{
    color: rgba(255, 255, 255, 0.60);
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.225px;
}

.subteam-username{
    color: #FFF;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.3px;
}

.subteam-role{
    color: rgba(255, 255, 255, 0.60);
    font-size: 9px;
    font-weight: 500;
    letter-spacing: -0.135px;
}