.notice-block{
    padding: 10px;
    width: 90%;
    background-color: #FFEFF3;
    border-radius: 10px;
    margin-right:20px;
}

.notice-body{
    flex-direction: row;
    display: flex;
}

.notice-icon{
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-left: 5px;
    margin-top:10px;
}

.text-shared{
    color: #808080;
    font-family: "Poppins" , sans-serif;
}

.notice-title{
    margin-top:10px;
    font-size: 15px;
    font-weight: bold;
}

.notice-text{
    margin-left: 35px;
    margin-top:2px;
    font-size: 15px;
    font-weight: normal;
}