.textBubbles{
    width: calc(100% - 30px);
    white-space: pre-line;
    margin-left: 20px;
    margin-top: 0px;
    margin-bottom: 0px !important;
}

.text-bubble-container .save-button {
    opacity: 0; /* Fully transparent initially */
    visibility: hidden; /* Not visible and not interactable */
    max-height: 0; /* Collapse the element so it doesn't take up space */
    overflow: hidden; /* Hide any overflowing content */
    transition: opacity 0.5s, visibility 0.5s, max-height 0.1s ease; /* Transition for opacity, visibility, and max-height */
}

.text-bubble-container:hover .save-button {
    margin-top:5px;
    opacity: 1; /* Fully visible when the container is hovered */
    visibility: visible; /* Make it visible and interactable */
    max-height: 50px; /* Adjust this value based on the content's size */
}

.save-button{
    border: none;
    transition: none;
    background-color: transparent;
}

.save-button-icon{
    max-height: 25px;
    height: 2.5vh;
    max-width: 25px;
    min-width: 20px;
}

.save-button-text{
    margin-left: 6px;
    font-size: 16px;
    font-weight: normal;
    color: #8d8d8d;
    font-family: "Poppins" , sans-serif;
    text-align: start;
    transition: color 0.4s;
}

.save-button-icon:hover + .save-button-text,
.save-button-text:hover {
    color:#000;
    font-weight: bold;
}

