.toolbar-element-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    max-width: 65px;
    cursor: pointer;
}

.toolbar-element-icon{
    height: 10vh;
    max-height: 50px;
}

.toolbar-element-text{
    font-size: 15px;
    font-weight: bold;
    color: #404040;
    font-family: "Poppins" , sans-serif;
    margin-top: 2px;
    width: 100%;
    text-align: center;
    transition: color 0.10s ease-in-out;
}

.toolbar-element-box:hover .toolbar-element-text{
    color: #FF003E;
}