.util-button{
    border: none;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-height: 75px;
    font-size: 16px;
    font-family: "Poppins" , sans-serif;
    font-weight: 600;
}

.util-button-icon{
    height: 2.5vh;
    min-height: 10px;
    max-height: 20px;
    margin-left: 15px;
}