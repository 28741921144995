.add-more-element-row-box{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    height: fit-content;
    margin: 0px 10px 0px 10px;
    padding: 5px;
    border-radius: 10px;
}
.add-more-element-row-box:hover{
    cursor: pointer;
    background-color: #FFACC0;
}
.add-more-element-icon-box{
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 75px;
    min-height: 40px;
    height: 7.5vh;
    max-width: 75px;
    min-width: 40px;
    width: 3.8vw;
    border-radius: 10px;
    background-color: #FFEFF3;
    margin-right: 0.5vw;
}
.add-more-element-icon{
    max-height: 40px;
    height: 4.5vh;
    min-height: 20px;
    max-width: 40px;
    min-height: 20px;
}

.add-more-element-child-column{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 8vh;
    margin-left: 10px;
    margin-right: 10px;
}

.add-more-element-child-title{
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
}

.add-more-element-child-subtitle{
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-top: 0.2vh;
    margin-bottom: 0px;
}