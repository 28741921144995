.textBubbles{
    width: calc(100% - 30px);
    margin-left: 20px;
    margin-top: 0px;
    margin-bottom: 15px;
}
.teamBubbles{
    width: calc(100% - 30px);
    margin-left: 20px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
}