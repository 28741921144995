.header-button{
    border: none;
    transition: none;
    background-color: transparent;
    align-items: center;
    justify-content: center !important;
    margin-left: 15px;
    margin-right:15px;
}
.header-button-row{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-button-icon{
    background-color: transparent;
    max-height: 30px;
    height: 4vh;
    max-width: 30px;
}

.header-button-text{
    font-family: "Poppins" , sans-serif;
    text-align: start;
    margin-left: 7px;
}