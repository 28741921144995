.BackGround{
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: flex-start;
    top: 0;
    left: 0;
    flex-direction: column;
}

.result-body{
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    width: 95vw;
    flex-direction: row;
    justify-content: space-between;
}

.result-body-leftwidget{
    height: 60vh;
    width: 10vw;
    background-color: transparent;
    color: #262626;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.result-body-chat{
    height: 83vh;
    width: 40vw;
    margin-right: 5vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.chatcopilot-question-title-block{
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 999;
    align-items: center;
}

.chatcopilot-question-title-block-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    padding: 0px 10px 0px 10px;
    margin-bottom: 5px;
    border-radius: 10px;
}

.chatcopilot-question-title{
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    color: #000;
    font-family: "Poppins" , sans-serif;
    text-align: start;
    margin-top: 11px;
}

.chatcopilot-question-title-icon{
    height: 15px;
    width: 15px;
    margin-left: 10px;
}

.chatcopilot-question-title-block:hover .chatcopilot-question-title-block-button{
    background-color: #f5f5f5;
}

.result-body-chat-texts{
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.result-body-chat-texts::-webkit-scrollbar {
    display: none;
}

.bottomref{
    align-self: flex-end !important;
    margin-top:35px
}
