.hide{
    display: none;
}

.tab-main-box{
    border-radius: 20px;
    background-color: transparent;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
}

.survey{
    border-radius: 20px;
    border: 2px solid #000;
    min-height: 57px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-width: 52vw;
    margin-right: 20px;
    padding: 0px 20px;
}

.survey-child{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.survey-category{
    border-radius: 20px;
    background-color: #FE3C6B;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.18px;
    max-width: 260px;
    max-height: 50px;
}

.survey-title{
    color: #000;
    text-align: start;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.375px;
    margin: 0vw ;
}

.survey-close-button{
        border-radius: 10px;
        background-color: rgba(105, 116, 221, 0.15);
        display: flex;
        align-items: center;
        justify-content: center;
        height: fit-content;
        width: fit-content;
        border: none;
        position: relative;
        right: 0;
}


.survey-close-button-text{
    font-size: 16px;
    font-weight: bold;
}

.bold{
    font-weight: 700;
}

.results{
    height: 89px;
    border-radius: 20px;
    background-color: #090E41;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.27px;
    text-decoration: none;
    width: 10vw;
}

.results:hover{
    text-decoration: none;
    color: #FFF;
}

.modal-exit-button{
    padding: 10px 15px 0 0;
    background: none;
    border: none;
}

.modal-delete-button, .modal-cancel-button{
    background: none;
    border: 1px solid grey;
    border-radius: 5px;
    transition: background-color 0.1s ease-out;
}

.modal-delete-button:hover{
    background-color: red;
    color: white;
}