.demographic-sidebar-back-button{
    border: none;
    background-color: transparent;
    max-width: 40px;
    max-height: 40px;
}

.demographic-sidebar-filter-title{
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: 10px;
    font-size: 25px;
    font-weight: bold;
    color: #000;
    font-family: "Poppins" , sans-serif;
    text-align: start;
}

.clipboardsidebar-main {
    max-height: 100%; /* Fill the sidebar container */
    overflow: hidden; /* Prevent the main container from scrolling */
    display: flex;
    flex-direction: column; /* Organizes children elements in a column */
}

.clipboard-text-box {
    margin-top: 20px;
    max-height: calc(100% - Xpx); /* X = combined height of other elements */
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    padding-bottom: 40px;
}

.clipboard-text-box::-webkit-scrollbar {
    display: none;
}

.selectquestion-text-box {
    margin-top: 20px;
    max-height: calc(100% - Xpx); /* X = combined height of other elements */
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    padding-bottom: 40px;
}

.selectquestion-text-box::-webkit-scrollbar {
    display: none;
}


