.create-notice-block{
    display: flex;
    flex-direction: column;
    background-color: #FFEFF3;
    border-radius: 10px;
    margin-bottom: 0px;
}

.create-panel-title-body{
    flex-direction: row;
    display: flex;
}

.create-panel-title-icon{
    width: 30px;
    height: 30px;
    margin-right: 15px;
}

.create-panel-title-text{
    color: #000;
    font-size: 30px;
    font-weight: bold;
    font-family: "Poppins" , sans-serif;
}
