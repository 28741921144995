.drawer-sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 1000; /* Ensure it's above other content but below the sidebar */
}

.drawer-sidebar {
    padding-left: 40px;
    padding-top:40px;
    position: fixed;
    right: -100%; /* Initially hidden */
    top: 0;
    bottom: 0;
    width: 35vw;
    max-width: 650px;
    background-color: white;
    transition: right 0.6s ease-in-out; /* Smooth transition for opening/closing */
    overflow-y: auto; /* Adds scroll if content overflows */
    /*z-index: 1001; Above the overlay */
}

.sidebar-container {
    height: 100vh; /* or another appropriate height */
    overflow: hidden; /* Prevent scrolling of the sidebar itself */
}

.drawer-sidebar.open {
    right: 0; /* Move into view */
}
