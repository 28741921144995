.modal-popup-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensures it is above other content but below the modal */
}

.modal-popup {
    background-color: white;
    width: 60vw;
    height: 65vh;
    min-width: 500px;
    min-height: 450px;
    padding: 20px;
    border-radius: 20px;
    box-sizing: border-box; /* Include padding and border in width and height */
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 1001; /* Sit on top */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal-back-button{
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 600;
}

.modal-back-button:hover{
    cursor: pointer;
    color: #FF003E;
}

.modal-back-icon{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.modal-body-children {
    width: 100%;
    height: 55vh;
    min-height: 350px;
    display: flex;
    align-items: center;
    justify-content: center; /* Add this line to center vertically */
    overflow-y: hidden;
    scrollbar-width: none;
}