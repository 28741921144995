.funnel-page-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    width: 100%;
    height: 100%;
}

.funnel-logo{
    position: fixed;
    top: 0;
    left: 0;
    margin: 2vw;
    width: 10vw;
    max-width: 150px;
    min-width: 50px;
}

.funnel-body{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 95%;
}

.funnel-page-spacer{
    width: 10vw;
}