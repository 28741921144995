.form-group {
    padding: 0px !important;
    margin: 0px !important;
}

.textfield-form{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #FFEFF3;
    width: 100%;
    border-radius: 15px;
    height: 8vh;
    width: 40vw;
    margin-left: 15px;
}

.textfield-input {
    margin-left: 25px;
    color: #262626 !important;
    font-size: 16px;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
    background-color: #FFEFF3;
    border: none !important;
    width: 80%;
} 

.textfield-input::placeholder {
    color: #7D7D7D !important;
    font-size: 16px;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
    width: auto;
}
  
.textfield-input:focus {
    color: #262626;
    border: none !important;
    outline: none !important;
}

.textfield-submit-button{
    border: none;
    transition: none;
    background-color: #FF003E;
    border-radius: 15px;
    height: 35px;
    width: 100px;
    color: #fff;
    font-family: "Poppins" , sans-serif;
    font-size: 15px;
    align-self: flex-end;
    margin: 5px 10px;
}
