
.popup-body-box{
    height: 55vh;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 80%;
    width: max-content;
}

.popup-body-title{
    width: 25vw;
    font-family: "Poppins", sans-serif;
    font-size: 3vw;
    font-weight: 600;
}

.popup-body-subtitle{
    width: 25vw;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
}

.popup-body-accesscode-box{
    border-radius: 20px;
    background-color: #FF003E;
    width: 25vw;
    height: 10vh;
    min-height: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    margin-bottom: 10px;
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
}

.popup-body-accesscode-box:hover{
    opacity: 1;
}

.popup-body-accesscode-text{
    font-family: "Poppins", sans-serif;
    font-size: 3vw;
    font-weight: 600;
    color: white;
    margin-top: 1.5vh;
}

.popup-button-box-row{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25vw;
}


@media screen and (max-width: 768px) {
    .popup-body-title, .popup-body-accesscode-text {
        font-size: 5vw; /* Larger size for smaller screens */
    }

    .popup-body-title, .popup-body-subtitle, .popup-body-accesscode-box {
        width: 50vw; /* Adjust width for smaller screens */
    }
}

@media screen and (max-width: 480px) {
    .popup-body-title, .popup-body-accesscode-text {
        font-size: 6vw; /* Even larger size for very small screens */
    }

    .popup-body-title, .popup-body-subtitle, .popup-body-accesscode-box {
        width: 75vw; /* Adjust width for very small screens */
    }
}