.create-function-panel-background{
    background-color: #FFEFF3;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.create-function-panel-body{
    width: 50vw;
    min-height: 75vh;
    display: flex;
    flex-direction: column;
}

.create-function-panel-title-row{
    width: 50vw;
    height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.create-function-spacer-150px-vertical{
    height: 150px;
}

.create-element-box-for-hover{
    width: max-content;
    height: max-content;
}

.create-function-panel-loading-box{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
}

.create-element-input-box {
    background-color: transparent;
    border: none;
    width: 90%;
    font-family: "Poppins", sans-serif;
    align-items: center;
    outline: none; /* Removes the default focus outline */
    white-space: pre-wrap !important; /* Allows text to wrap */
    overflow-wrap: normal;
    resize: none;
}

.create-element-input-box:focus {
    font-weight: bold; /* Makes text bold when input is focused */
}

.space-left-3vw{
    margin-left: 3vw;
}

.create-function-panel-bottom-buttons{
    width: 47vw;
    margin-left: 3vw;
    margin-top: 5vh;
    height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.template-element-row-box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.template-element-child-column{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; /* Updated to stretch the height */
    margin-left: 15px;
    margin-right: 40px;
}

.template-element-child-title{
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-top: 3vh;
}

.template-element-child-subtitle{
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-top: -1vh;
}
.add-filter-add-window {
    width: 20vw;
    height: 45vh;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: #FFFFFF;
    z-index: 1000;
    position: absolute; /* Change position to absolute */
    top: 51%; /* Adjust the top position */
    left: 78%; /* Adjust the left position */
    transform: translate(-50%, -50%); /* Center the component */
}

